import { useCallback, useState } from "react";

type UseClipboardReturn = {
  copyToClipboard: (text: string) => void;
  isCopied: boolean;
};

export default function useClipboard(): UseClipboardReturn {
  const [isCopied, setIsCopied] = useState(false);

  const copyToClipboard = useCallback((text: string) => {
    if (!navigator.clipboard) {
      console.error("Clipboard API not supported");
      return;
    }

    if (navigator.clipboard) {
      setIsCopied(true);
      navigator.clipboard.writeText(text);
      // Reset the state after 5 seconds
      setTimeout(() => {
        setIsCopied(false);
      }, 5000);
    }
  }, []);

  return { copyToClipboard, isCopied };
}
