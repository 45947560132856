import clsx from "clsx";
import { HTMLAttributes } from "react";

export type TableContainerProps = HTMLAttributes<HTMLDivElement> & {
  variant?: "paper" | "card";
  inside?: boolean;
  fullHeight?: boolean;
};

const TableContainer = ({
  variant = "card",
  inside = false,
  className,
  fullHeight = false,
  children,
}: TableContainerProps) => {
  return (
    <div
      className={clsx(className, "flow-root", {
        "h-full": fullHeight,
        "mt-8": !inside,
        "mt-2": inside,
      })}
    >
      <div
        className={clsx("-mt-2 overflow-x-auto sm:-mx-6 lg:-mx-8", {
          "h-full": fullHeight,
        })}
      >
        <div
          className={clsx("inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8", {
            "h-full": fullHeight,
          })}
        >
          <div
            className={clsx({
              "min-h-[60vh]": fullHeight,
              "[&_td]:first:sm:pl-0 [&_th]:sm:pl-0": variant === "paper" && !inside,
              "overflow-hidden shadow ring-1 ring-black ring-opacity-5 sm:rounded-md [&_tbody]:bg-white [&_td]:first:sm:pl-4 [&_th]:sm:pl-4 [&_thead]:bg-gray-50":
                variant === "card",
            })}
          >
            {children}
          </div>
        </div>
      </div>
    </div>
  );
};

export default TableContainer;
