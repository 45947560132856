import { type ClassValue, clsx } from "clsx";
import { twMerge } from "tailwind-merge";

/**
 * Merge Tailwind CSS classes using clsx and twMerge.
 * @param args - The Tailwind CSS classes to merge.
 * @returns The merged CSS classes.
 */
export const twClass = (...args: ClassValue[]) => {
  return twMerge(clsx(args));
};

/**
 * Generate a random number within a specified range.
 * @param min - The minimum value of the range.
 * @param max - The maximum value of the range.
 * @returns A random number within the specified range.
 */
export const randomInRange = (min: number, max: number) => {
  return Math.random() * (max - min) + min;
};

/**
 * Shuffle the elements of an array randomly.
 * @param array - The array to shuffle.
 * @returns The shuffled array.
 */
export const shuffleArray = <T>(array: T[]): T[] => {
  const shuffledArray = [...array]; // Copy the array
  if (shuffledArray?.length < 2) {
    return shuffledArray;
  }

  return shuffledArray.sort(() => Math.random() - 0.5);
};

/**
 * Creates an array of the specified length filled with the specified value.
 * @param length The length of the array.
 * @param value The value to fill the array with.
 * @returns The created array.
 */
export const createArray = <T>(length: number, value: T): T[] => {
  // Create an array of the specified length filled with the specified value
  return Array.from({ length }, () => value);
};
