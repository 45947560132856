import { LocaleSwitcherType, LocaleType } from "@/types/locale";

export const LOCALES = ["en", "vi"] as const;
export const DEFAULT_LOCALE: LocaleType = "vi";

export type Locale = (typeof LOCALES)[number];

export const LOCALES_SWITCHER: LocaleSwitcherType[] = [
  {
    locale: "en",
    name: "common.english",
    icon: "America",
  },
  {
    locale: "vi",
    name: "common.vietnamese",
    icon: "Vietnam",
  },
];
